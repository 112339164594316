import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'Default',
      title: 'Главная',
      auth: false,
      name: 'Главная'
    },
    component: () =>
      import(/* webpackChunkName: 'home' */ '../views/HomeView.vue')
  },
  {
    path: '/pre-confirmation',
    name: 'pre-confirmation',
    meta: {
      layout: 'Default',
      title: 'Главная',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/CheckUserOrder.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'Default',
      title: 'Регистрация',
      auth: false,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/SignupView.vue')
  },
  {
    path: '/profile/identity',
    name: 'profileIdentity',
    meta: {
      layout: 'Default',
      title: 'Идентификация профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ProfileIdentificationView.vue')
  },
  {
    path: '/profile/order',
    name: 'profileOrder',
    meta: {
      layout: 'Default',
      title: 'Идентификация профиля',
      auth: true,
      name: 'Регистрация'
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ProfileOrderView.vue')
  },
  {
    path: '/profile/checkOrder',
    name: 'profileCheckOrder',
    meta: {
      layout: 'Default',
      title: 'Идентификация профиля',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'signup' */ '../views/ProfileCheckOrderView.vue')
  },
  {
    path: '/profile/confirmOrder',
    name: 'confirmOrder',
    meta: {
      layout: 'Default',
      title: 'Смарт займ - Главная',
      auth: true,

    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/ProfileContractConfirmView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    meta: {
      layout: 'Default',
      title: 'Авторизация',
      auth: false,
      name: 'Авторизация'
    },
    component: () =>
      import(/* webpackChunkName: 'signin' */ '../views/SigninView.vue')
  },
  {
    path: '/pay/callback',
    name: 'pay/callback',
    meta: {
      layout: 'Default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/PayCallback.vue')
  },
  {
    path: '/pay/loanpay',
    name: 'pay/loanpay',
    meta: {
      layout: 'Default',
      title: '',
      auth: false,
      name: 'callback'
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/LoanPay.vue')
  },
  {
    path: '/confirm-debt',
    name: 'confirm-debt',
    meta: {
      layout: 'Default',
      title: '',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: 'pay' */ '../views/ConfirmDebt.vue')
  },
  {
    path: '/loanlist',
    name: 'loanlist',
    meta: {
      layout: 'Dashboard',
      title: 'Список займов',
      auth: true,
      name: 'Список займов'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/LoanListView.vue')
  },
  {
    path: '/special-offers',
    name: 'special-offers',
    meta: {
      layout: 'Dashboard',
      title: 'Специальные предложения',
      auth: true,
      name: 'Специальные предложения'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/SpecialOffersView.vue')
  },
  {
    path: '/bring-friend',
    name: 'bring-friend',
    meta: {
      layout: 'Dashboard',
      title: 'Приведи друга',
      auth: true,
      name: 'Приведи друга'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/BringFriendView.vue')
  },

  {
    path: '/loan/:id',
    name: 'loan',
    meta: {
      layout: 'Dashboard',
      title: 'Текущий займ',
      auth: true,
      name: 'Текущий займ'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/LoanView.vue')
  },
  {
    path: '/cards',
    name: 'cards',
    meta: {
      layout: 'Dashboard',
      title: 'Мои карты',
      auth: true,
      name: 'Мои карта'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/CardsView.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      layout: 'Dashboard',
      title: 'История займов',
      auth: true,
      name: 'История займов'
    },
    component: () =>
      import(/* webpackChunkName: 'history' */ '../views/HistoryView.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      layout: 'Dashboard',
      title: 'Мои обращения',
      auth: true,
      name: 'Мои обращения'
    },
    component: () =>
      import(/* webpackChunkName: 'history' */ '../views/ReportsView.vue')
  },
  {
    path: '/documents',
    name: 'documents',
    meta: {
      layout: 'Dashboard',
      title: 'Документы',
      auth: true,
      name: 'Документы'
    },
    component: () =>
      import(/* webpackChunkName: 'documents' */ '../views/DocumentsView.vue')
  },
  {
    path: '/files',
    name: 'files',
    meta: {
      layout: 'Dashboard',
      title: 'Файлы',
      auth: true,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'files' */ '../views/FilesView.vue')
  },
  {
    path: '/bring-friend-public',
    name: 'bring-friend-public',
    meta: {
      layout: 'Default',
      title: 'Приведи друга',
      auth: false,
      name: 'Приведи друга'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/AddFriendPublicView.vue')
  },
  {
    path: '/problems-and-suggestions',
    name: 'problems-and-suggestions',
    meta: {
      layout: 'Default',
      title: 'Проблемы и предложения',
      auth: false,
      name: 'Проблемы и предложения'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/ProblemsAndSuggestionsView.vue')
  },
  {
    path: '/make-complaint',
    name: 'make-complaint',
    meta: {
      layout: 'Default',
      title: 'Подать жалобу',
      auth: false,
      name: 'Подать жалобу'
    },
    component: () =>
      import(/* webpackChunkName: 'loan' */ '../views/MakeComplaintView.vue')
  },
  {
    path: '/qr',
    name: 'qr',
    meta: {
      layout: 'Default',
      title: 'Файлы',
      auth: false,
      name: 'Файлы'
    },
    component: () =>
      import(/* webpackChunkName: 'qr' */ '../views/QrView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
