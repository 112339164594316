import {notificationsStore} from "@/store/notifications"
import iziToast from "izitoast";

export class NotificationsLayer{
  constructor() {
    this.notificationsStore = notificationsStore()
  }

  install(app){
    app.provide('NotificationsLayer', this)
  }

  /** @param notification {{message: string, key?:string, type?: "error" | "success", timeout?: number | false }} */
  addNotification(notification){
    if(!notification.message){
      return;
    }
    let key = Date.now()

    if(notification.key){
      key = notification.key
    }
    this.notificationsStore.$patch((state) => {
      state.notifications[key] = notification
    })
  }

  removeNotification(key){
    this.notificationsStore.$patch((state) => {
      if(state.notifications[key]){
        const el = document.querySelector('#toast' + key + '.iziToast');

        if(el){
          iziToast.hide({}, el);
        }
        delete state.notifications[key]
      }
    })
  }

  setNotificationShown(key){
    this.notificationsStore.$patch((state) => {
      if(state.notifications[key]){
        state.notifications[key]['hasShown'] = true
      }
    })
  }

  addPopup(key, componentName, ppData) {
    this.notificationsStore.$patch((state) => {
      if(state.popups[key]){
        return;
      }
      state.popups[key] = {
        component: componentName,
        isOpen: false,
        canClose: false,
        data: ppData
      };
    })
  }

  removePopup(key){
    this.notificationsStore.$patch((state) => {
      if(state.popups[key]){
        delete state.popups[key];
      }
    })
  }

}
