<template>
  <component
    :is="comp"
    v-if="comp"
    v-bind="props.data"
    @vue:mounted="onReady"
    @close="onClose"
  />
</template>

<script setup>
import { computed, defineAsyncComponent } from "vue";

const onReady = (data) => {
  emits('ready', data);
}

const onClose = (data) => {
  emits('close', data);
}

const props = defineProps({
  component: {
    type: String,
    default: '',
  },
  data: {
    type: Object,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: {}
  },
});

const emits = defineEmits(['ready', 'close']);

const comp = computed(() => {
  try {
    return defineAsyncComponent(() => import(`./../common/popups/${props.component}.vue`).catch((e) => {
      // eslint-disable-next-line no-console
      console.warn(`[AsyncComponent] Component "${props.component}" wasn't found in Sections path`)
      emits('close')

      return undefined;
    }));
  }
  catch (e){
    // eslint-disable-next-line no-console
    console.warn(`[AsyncComponent] Component "${props.component}" wasn't found in Sections path`);
    emits('close')

    return undefined;
  }
});

</script>
