export const ClickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = function (event) {
      if(event.target){
        if (!(el == event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      }
    }

    document.addEventListener('click', el.clickOutsideEvent);

  },
  beforeUnmount: (el) => {
    if(el.clickOutsideEvent){
      document.removeEventListener("click", el.clickOutsideEvent)
    }
  }
}
