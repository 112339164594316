<template>
  <div />
</template>
<script setup>

import {storeToRefs} from "pinia";
import {notificationsStore} from "@/store/notifications";
import {inject, watch} from "vue";
import "izitoast/dist/css/iziToast.min.css"
import iziToast from "izitoast";

/** @var { NotificationsLayer } notificationLayer */
const notificationLayer = inject('NotificationsLayer')

const { notifications } = storeToRefs(notificationsStore());

const commonParams = {
  progressBar: false,
  close: true,
  displayMode: 0,
  messageColor: '#fff',
  animateInside: false,
  class: "notification-item"
}

watch(notifications, /** @param neoVal {{[key:string]: {message: string, key?:string, type?: "error" | "success", timeout?: number | false, hasShown?:boolean }}} */(neoVal) => {
  const isBigScreen = window.innerWidth >= 768;

  const position = isBigScreen ? 'topRight' : 'topCenter'
  const transitionIn = "fadeInLeft";
  const transitionOut = "fadeOutRight";

  Object.keys(neoVal).forEach((key) => {
    if(!neoVal[key].hasShown){

      notificationLayer.setNotificationShown(key);

      iziToast.show(Object.assign(commonParams, {
        id: 'toast' + key,
        position: position,
        message: neoVal[key]['message'],
        timeout: neoVal[key]['timeout'] || (neoVal[key]['timeout'] === false ? false : 5000),
        backgroundColor: neoVal[key]['type'] === 'success' ? "rgba(107, 211, 144, 0.80)" : "rgba(255, 0, 0, 0.75)",
        transitionIn: transitionIn,
        transitionOut: transitionOut,
        onClosed: () => {
          notificationLayer.removeNotification(key);
        },
      }));
    }
  });
}, {
  deep: true
});

</script>
<style lang="scss">
.iziToast.notification-item>.iziToast-body .iziToast-message{
  font-size: 16px;
  line-height: 16px;
}
.iziToast.notification-item{
  font-family: inherit;
  display: inline-flex;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  font-weight: 600;

  .iziToast-close{
    /*background-image: url("@/assets/images/buttons/account-menu-close.svg");*/
    background-size: 18px;
    opacity: 1;
  }

  @media(max-width: 768px){
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    .iziToast-close{
      position: static;
      display: flex;
      height: 37px;
      margin-left: 10px;
    }
    .iziToast-body{
      margin-left: 0;
    }
  }
}

.iziToast-wrapper.iziToast-wrapper-topRight{
  padding-right: 50px;
  padding-top: 150px;
}

</style>
