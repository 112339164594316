import {defineStore} from "pinia";

export const signupStore = defineStore('signUp', {
  state: () => ({
    csrf: '',
    // eslint-disable-next-line camelcase
    user_id: '',
    cards: [],
    step: 0,
    availableFileTypes: [],
  }),
  getters: {
    authClient: () => ![null, undefined, "false"].includes(localStorage.getItem('auth:client'))
  }
})
