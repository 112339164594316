export const numberWithSpaces = (x) => {
  if (x === undefined) {
    return
  }
  var parts = x.toString().split(".");

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (parts[1] !== undefined) {
    parts[1] = parts[1].substr(0, 2);
  }

  return parts.join(".");
}
