import apiRequest from "@/middlewares/api-request"
import { dashboardStore } from "@/store/dashboard"
import axios from "axios"
import DeviceDetector from "device-detector-js"
import {numberWithSpaces} from "@/utils/numberWithSpaces";
import {ORDER_STATUS_IDS} from "@/constants/order_statuses";

export class DashboardLayer{
  constructor() {
    this.dashboardStore = dashboardStore();
    this.apiKey = '59b6004a026a788bd4be562df6c03365d8a489a5'; //todo: заменить ключ!!!
  }

  install(app){
    app.provide('DashboardLayer', this)
  }

  setNotificationLayer(notificationLayer){
    this.notificationLayer = notificationLayer
  }

  setUserInfo({data}) {
    this.dashboardStore.$patch({
      user: data.data.client
    })
  }

  getUserInfoData() {
    return this.dashboardStore.$state.user
  }

  setActiveOrders(res){
    if(res?.data?.data?.data?.amount){
      this.dashboardStore.$patch({
        activeOrders: [res.data.data.data]
      })
    }
  }

  setOrders(res){
    let activeOrders = res.data.data.data.filter((order) => {
      return [ORDER_STATUS_IDS.GIVEN, ORDER_STATUS_IDS.EXPIRED].includes(order.status_id)
    })
    this.setActiveOrders(activeOrders)

    this.dashboardStore.$patch({
      order: activeOrders?.[0],
    })

    this.dashboardStore.$patch({
      orders: res.data.data.data
    })
  }

  getOrdersData() {
    return this.dashboardStore.$state.orders
  }

  setPayoutMethods(res){
    this.dashboardStore.$patch((state) => {
      state.activePayoutMethods = (res?.data.length > 0) ? [...res.data] : []
    })
  }

  setPaymentMethods(res){
    this.dashboardStore.$patch((state) => {
      state.activePaymentMethods = (res?.data.length > 0) ? [...res.data] : []
    })
  }

  setSbpBakns(res){
    this.dashboardStore.$patch((state) => {
      state.spbBanks = (res?.data.data.banks.length > 0) ? [...res.data.data.banks] : []
    })
  }

  setHistory(res){
    res.data.data.data.map((item) => {
      item.time = item.created_at?.split(' ')?.[1]
      const [year, month, day] = item.created_at.split(/[^\d]/)
      const str = `${day}.${month}.${year.slice(-2)}`

      // eslint-disable-next-line camelcase
      item.created_at = str

      return item
    })
    this.dashboardStore.$patch({
      history: res.data.data.data
    })
  }

  setDocuments(res){
    const stopTypes = [
      'personal_data_consent',
      'rules_rejection_reason',
      'rejection_reason_application',
      'rules_rejection_reason',
      'asp_agreement',
      'analogue_signature_agreement',
      'service_agreement',
      'loan_granting_rules'
    ]

    this.dashboardStore.$patch({
      documents: res?.data?.data?.data.map((docList) => {
        return docList.filter((doc) => !stopTypes.includes(doc?.type))
      })
    })
  }

  checkPhoto({id, type, params}){
    let status = params?.status

    switch (type) {
    case 'selfie':
      type = 'Селфи с паспортом'
      break
    case 'passport':
      type = 'Первая страница паспорта'
      break
    case 'reg_address':
      type = 'Страница регистрации'
      break
    case 'card_photo':
      type = 'Фото банковской карты'
      break
    default:
      break
    }

    if (!status) {
      status = 'pending'
    }

    this.dashboardStore.$patch((state) => {
      state.photos.push({
        url: `${process.env.VUE_APP_API_URL}api/files/get/${id}/auth`,
        type,
        id,
        status: status,
        // eslint-disable-next-line camelcase
        initial_type: params?.type
      })
    })
  }

  setToken(token){
    this.dashboardStore.$patch({
      token: token
    })
    localStorage.setItem('token', token)
  }

   saveToLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  getFromLocalStorage(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  setCards(cards){
    cards?.map((item) => (item.value = false))
    this.dashboardStore.$patch({
      lkCards: cards.slice(0, -1)
    })
  }

  setBanner(banner){
    this.dashboardStore.$patch({
      banner: banner
    })
  }

  setAdmin(flag){
    this.dashboardStore.$patch({
      admin: flag
    })
  }

  setProlongationDocuments(docs){
    if (docs === undefined) return
    let filtredDocs
    const docsToPush = docs
      .filter(
        (item) =>
          !item?.title.includes('Сертификат') &&
          !item?.title.includes('Справка о закрытии займа')
      )
      .map((item) => {
        if (
          item?.type === 'subscription_service_rules_telejur' ||
          item?.type === 'application_for_the_services_of_telejurist'
        ) { item.required = false } else if (item.required !== undefined) item.required
        else item.required = true

        item.link = item.link !== undefined ? item.link : '/' //todo throw error?
        item.name = item?.title
        item.match = []
        item.errorBag = []

        return item
      })

    filtredDocs = docsToPush.sort((a, b) => a.order - b.order)
    this.dashboardStore.$patch({
      prolongationDocuments: filtredDocs
    })
  }

  setContractDocuments(docs){
    if (docs === undefined) return
    let filtredDocs
    const docsToPush = docs
      .filter(
        (item) =>
          !item?.title.includes('Сертификат') &&
          !item?.title.includes('Справка о закрытии займа') &&
          item?.type !== 'rejection_reason_application' &&
          item?.type !== 'rules_rejection_reason' &&
          item?.type !== 'insurance_certificate'
      )
      .map((item) => {
        if (
          item?.type === 'subscription_service_rules_doctor' ||
          item?.type === 'rules_rejection_reason' ||
          item?.type === 'rejection_reason_application' ||
          item?.type === 'consultation_with_doctor_application'
        ) { item.required = false } else if (item.required !== undefined) item.required
        else item.required = true

        item.link !== undefined ? item.link : '/' //todo throw error?
        item.name = item?.title
        item.match = []
        item.errorBag = []

        return item
      })

    filtredDocs = docsToPush.sort((a, b) => a.order - b.order)

    this.dashboardStore.$patch({
      contractDocuments: filtredDocs
    })
  }

  setCloseLoanDocuments(docs){
    if (docs === undefined) return
    let filtredDocs
    const docsToPush = docs
      .filter(
        (item) =>
          !item?.title.includes('Сертификат') &&
          !item?.title.includes('Справка о закрытии займа')
      )
      .map((item) => {
        if (
          item?.type === 'subscription_service_rules_doctor' ||
          item?.type === 'consultation_with_doctor_application'
        ) { item.required = false } else if (item.required !== undefined) item.required
        else item.required = true

        item.link = item.link !== undefined ? item.link : '/' //todo throw error?
        item.name = item?.title
        item.match = []
        item.errorBag = []

        return item
      })

    filtredDocs = docsToPush.sort((a, b) => a.order - b.order)
    this.dashboardStore.$patch({
      closeLoanDocuments: filtredDocs
    })
  }

  setOrderDocuments(docs){
    if (docs === undefined) return
    let filtredDocs
    const docsToPush = docs
      .filter(
        (item) =>
          !item?.title.includes('Сертификат') &&
          !item?.title.includes('Справка о закрытии займа') &&
          item?.type !== 'rules_rejection_reason' &&
          item?.type !== 'rejection_reason_application'
      )
      .map((item) => {
        if (
          item?.type === 'subscription_service_rules_doctor' ||
          item?.type === 'consultation_with_doctor_application' ||
          item?.type === 'subscription_service_rules_telejur' ||
          item?.type === 'rejection_reason_application'
        ) { item.required = false } else if (item.required !== undefined) item.required
        else item.required = true

        item.link !== undefined ? item.link : '/' //todo throw error?
        item.name = item?.title
        item.match = []
        item.errorBag = []

        return item
      })

    filtredDocs = docsToPush.sort((a, b) => a.order - b.order)
    this.dashboardStore.$patch({
      orderDocuments: filtredDocs
    })
  }

  setFormFields({data}){
    this.dashboardStore.$patch({
      formFields: data.data.fields
    })
  }

  setOrder({data}){
    this.dashboardStore.$patch({
      order: data.data.client.order
    })
  }

  getToken(){
    return this.dashboardStore.$state.token
  }

  getClientFromSmsLink({client, code}){
    return new Promise(resolve => {
      axios
        .get(`${process.env.VUE_APP_API_URL}api/clients/${client}/${code}`)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          resolve(err?.response)
        })
    })
  }
  // eslint-disable-next-line camelcase
  rebId({client_id, period, amount}){
    const date = new Date()

    const timezoneOffset = date.getTimezoneOffset()

    const timezone = -(timezoneOffset / 60)

    return new Promise((resolve) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}api/clients/confirm`, {
          // eslint-disable-next-line camelcase
          client_id,
          amount,
          period,
          timezone
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            this.notificationLayer?.addNotification?.({
              message: error?.response?.data?.message
            })
          }
          resolve(error?.response)
        })
    })
  }

  async redirectToBindCardLink() {
    await apiRequest.function(
      'GET',
      `api/pay/link/auth?referer=${window.location.pathname}${window.location.search}`,
      null,
      null,
      null,
      {Authorization: `Bearer ${this.getToken()}`}
    ).then(async () => {
      await apiRequest.function(
        'GET',
        `api/pay/link/auth`,
        null,
        null,
        null,
        {Authorization: `Bearer ${this.getToken()}`}
      ).then(res => {
        if (res.data.code === 200) {
          window.location.href = res.data.data.link
        }
      })
    })
  }

  notify(notification) {
    this.notificationLayer?.addNotification?.(notification)
  }

  // eslint-disable-next-line camelcase
  logIn({phone, password, recaptcha_token = null}){
    return new Promise((resolve) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}api/clients/login`, {
          phone,
          password,
          // eslint-disable-next-line camelcase
          recaptcha_token
        })
        .then((res) => {
          if (res?.data?.code === 200) {
            this.setToken(res.data.data.token)
            this.dashboardStore.$patch({
              'client_id': res.data.data?.client?.id,
              'authClient': res.data.data?.client?.id,
            })
            localStorage.setItem('auth:client', res.data.data?.client?.id)
          }

          resolve(res)
        })
        .catch((error) => {
          if (error.response.data?.code === 422) {
            this.setToken(error.response.data.data.token)
            this.dashboardStore.$patch({
              'client_id': error.response.data.data?.client?.id,
              'authClient': error.response.data.data?.client?.id,
            })
            localStorage.setItem('auth:client', error.response.data.data?.client?.id)
          }

          if (error?.response?.status === 422) {
            this.notificationLayer?.addNotification?.({
              message: error?.response?.data?.message
            })
          }
          resolve(error?.response)
        })
    })
  }

  getClientId() {
    return localStorage.getItem('auth:client');
  }

  logOut(){
    this.dashboardStore.$patch({
      authClient: false,
      // eslint-disable-next-line camelcase
      client_id: null,
      token: null,
    })
    localStorage.removeItem('userId')
    localStorage.removeItem('phone')
    localStorage.setItem('auth:client', false)
  }

  async getUserInfo(){
    return await apiRequest.function(
      'GET',
      `api/clients`,
      [this.setUserInfo.bind(this), this.setFormFields.bind(this), this.setOrder.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getActiveOrders(client){
    return await apiRequest.function(
      'GET',
      `api/clients/${client}/active_order`,
      [this.setActiveOrders.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  getActiveOrdersData() {
    return this.dashboardStore.$state.activeOrders
  }

  async getOrdersByClient(){
    return await apiRequest.function(
      'GET',
      `api/clients/orders`,
      [this.setOrders.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }


  async getPayoutMethods(){
    return await apiRequest.function(
      'GET',
      `api/payment_system/payout`,
      [this.setPayoutMethods.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getSbpQr(payload){
    let link = `api/pay/payment/${payload.contract}`

    link += `?prolongation=${payload.prolongation}&payment_system_id=${payload.payment_system_id}`

    if (payload?.amount) link += `&amount=${payload?.amount}`

    return await apiRequest.function(
      'GET',
      link,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getPaymentMethods(){
    return await apiRequest.function(
      'GET',
      `api/payment_system/payment`,
      [this.setPaymentMethods.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getSbpBanks(){
    return await apiRequest.function(
      'GET',
      `api/banks`,
      [this.setSbpBakns.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getHistory(client){
    return await apiRequest.function(
      'GET',
      `api/clients/${client}/credit_history`,
      [this.setHistory.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getDocuments(client){
    return await apiRequest.function(
      'GET',
      `api/clients/${client}/documents`,
      [this.setDocuments.bind(this)],
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

   async getRegisterDocuments() {
    return await apiRequest.function(
      'GET',
      'api/documents/register_documents',
      null,
      null,
      null,
      {}
    )
  }

  async getPreOrderDocuments() {
    return await apiRequest.function(
      'GET',
      'api/documents/preorder_documents',
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async getContractDocuments() {
    return await apiRequest.function(
      'GET',
      'api/documents/contract_documents',
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async loadDocument(doc) {
    const config = {
      responseType: 'arraybuffer'
    }

    const token = this.getToken();

    if(token){
      config['headers'] = {Authorization: `Bearer ${token}`}
    }

    try{
      await axios.get(process.env.VUE_APP_API_URL + 'api/' + doc.link, config).then(response => {
        const file = new Blob([response.data], {
          type: 'application/pdf'
        })
        const downloadLink = document.createElement('a')

        downloadLink.href = URL.createObjectURL(file)
        downloadLink.download = doc.name
        downloadLink.click()
      })
    }
    catch (e){ /* empty */ }
  }

  getPhotos(file){
    this.checkPhoto(file)
  }

  async getCards(client){
    const res = await apiRequest.function(
      'GET',
      `api/clients/${client}/cards`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    this.setCards(res?.data?.data?.cards)

  }

  async getBanner(client){
    const res = await apiRequest.function(
      'GET',
      `api/clients/${client}/remind_banner`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    this.setBanner(res?.data?.data?.banner)
  }

  async payLoan(payload){
    let link = `api/pay/payment/${payload.contract}`

    link += `?card_id=${payload.card_id}&type=${payload.type}&payment_system_id=${payload.payment_system_id}`
    if (payload?.prolongation) { link += `&prolongation=${payload.prolongation}` }

    if (payload?.amount) link += `&amount=${payload?.amount}`

    const res = await apiRequest.function('GET', link, null, null, payload, {
      Authorization: `Bearer ${this.getToken()}`
    })

    return res
  }

  async sendLoanPayCallback({search}){
    const res = await apiRequest.function(
      'GET',
      `api/pay/callbackPayment/${search}`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    if (res == undefined) return
    // eslint-disable-next-line no-unused-vars
    const { data, code = 400, message } = res.data

    if (code === 200) {
      // commit("SET_TOKEN", res.data.data.token)
    }

    return res
  }

  async getDashboardBindCardLink(){
    return await apiRequest.function(
      'GET',
      'api/pay/link',
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  // eslint-disable-next-line camelcase
  generateAspCode({client_id, asp_type, order_id}){
    return new Promise((resolve) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}api/clients/generate_asp`,
          {
            // eslint-disable-next-line camelcase
            asp_type,
            // eslint-disable-next-line camelcase
            order_id,
            // eslint-disable-next-line camelcase
            client_id
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.getToken()
            }
          }
        )
        .then((res) => {
          resolve(res)
        })
    })
  }

  // eslint-disable-next-line camelcase
  signAspCode({asp_code, order_id, route}){
    return new Promise((resolve) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}api/clients/${route}`,
          {
            // eslint-disable-next-line camelcase
            asp_code,
            // eslint-disable-next-line camelcase
            order_id
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.getToken()
            }
          }
        )
        .then((res) => {
          resolve(res)
        })
    })
  }

  // eslint-disable-next-line camelcase
  prolongationSign({order_id, asp_code}){
    return new Promise((resolve) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}api/clients/prolongation`,
          // eslint-disable-next-line camelcase
          { order_id, asp_code },
          {
            headers: {
              Authorization: 'Bearer ' + this.getToken()
            }
          }
        )
        .then((res) => {
          resolve(res)
        })
    })
  }

  getUnsignedDocs(client){
    return new Promise((resolve) => {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}api/clients/${client}/unsigned_documents`,
          {
            headers: {
              Authorization: 'Bearer ' + this.getToken()
            }
          }
        )
        .then((res) => {
          const allDocs = res?.data?.data?.data

          this.setProlongationDocuments(allDocs.PROLONGATION)
          this.setContractDocuments(allDocs.CONTRACT)
          this.setCloseLoanDocuments(allDocs.CONTRACT_CLOSE)
          this.setOrderDocuments(allDocs?.ORDER || [])
          resolve(res)
        })
    })
  }

  async getConfirmCodes({client, type}){
    return await apiRequest.function(
      'GET',
      `api/clients/${client}/codes/${type}`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async sendNewCode(data){
    return await apiRequest.function(
      'POST',
      `api/sms/send_una`,
      null,
      null,
      data
    )
  }

  getFormFields(){
    const fomFields = this.dashboardStore.$state.formFields

    if (!fomFields) {
      return []
    }
    const groups = Object.entries(fomFields).map( g => ({
      id: g[1][0].group.id,
      name: g[1][0].group.name,
      order: g[1][0].group.order,
      alias: g[1][0].group.alias || null,
      fields: g[1]
    }))

    /** собираем массив из полученных полей из конструктора */
    groups.forEach( group => {
      group.fields.forEach(field => {
        field['error'] = []

        const hasRelation = !!(field['relation_field'] && field['relation_field']['relation'])
        const isClientGroup = field['relation_field']['relation'] === 'client'
        const isContactPersonGroup = field['relation_field']['relation'] === 'contact_persons'

        if (isClientGroup) {
          field['value'] = this.dashboardStore.$state.user[field['relation_field']['column']]

          return
        }
        if (hasRelation && hasRelation) {
          field['value'] = this.dashboardStore.$state.user[field['relation_field']['relation']]?.[field['relation_field']['column']] || null
        }
        if (hasRelation && field['relation_field']['relation'] === 'extended') {
          const index = this.dashboardStore.$state.user.extended.findIndex( f => f['field_id'] === field.id)

          if (index !== -1) {
            field['value'] = this.dashboardStore.$state.user['extended'][index]['value'] || null
          }
        }
        if (hasRelation && isContactPersonGroup && this.dashboardStore.$state.user[field['relation_field']['relation']].length) {
          const value = this.dashboardStore.$state.user[field['relation_field']['relation']][0][field['relation_field']['column']]

          if (value) {
            field['relation_id'] = this.dashboardStore.$state.user[field['relation_field']['relation']][0]['id']
          }
          field['value'] = value || null
        }
      })
    })

    return groups
  }

  isMobile(){
    const detector = new DeviceDetector()
    const device = detector.parse(navigator.userAgent)

    return device.device.type === 'smartphone'
  }

  setMoratoriumStatus(data){
    this.dashboardStore.$patch({
      moratorium: {
        status: Boolean(data?.status),
        // eslint-disable-next-line camelcase
        end_date: data?.end_date
      }
    })
  }

  async createDocuments(client){
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}api/clients/documents/store/${client}`,
          null,
          {
            headers: {
              Authorization: 'Bearer ' + this.getToken()
            }
          }
        )
        .then((res) => {
          if (res?.response?.status === 500) { throw new Error('Ошибка сервера') } else resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  async resetPassword({step, payload}){
    if (step === 0) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_URL}api/clients/password_reset_request`,
            payload
          )
          .then((res) => {
            if (res?.response?.status === 500) { throw new Error('Ошибка сервера') } else resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
    if (step === 1) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${process.env.VUE_APP_API_URL}api/clients/password_reset_confirm/${payload?.user_id}`,
            payload
          )
          .then((res) => {
            if (res?.response?.status === 500) { throw new Error('Ошибка сервера') } else resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
    if (step === 2) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${process.env.VUE_APP_API_URL}api/clients/password_reset/${payload?.user_id}`,
            payload
          )
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }

  async getClient(){
    return await apiRequest.function('GET', 'api/clients', null, null, null, {
      Authorization: `Bearer ${this.getToken()}`
    })
  }

  async checkSms({method, url, code, type, phone}){
    return await apiRequest.function(
      method,
      `api/clients/${url}`,
      null,
      null,
      { code, type, phone }
    )
  }

  async getMoratoriumStatus({url=''}){
    const res = await apiRequest.function(
      'GET',
      `api/clients/moratorium${url}`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    this.setMoratoriumStatus(res.data?.data?.data)

    return res?.data?.data?.data
  }

  async sendFeedback(payload){
    return await apiRequest.function(
      'POST',
      'api/feedback/store',
      null,
      null,
      payload
    )
  }

  async sendGuruLeads({query, clientId}){
    return await apiRequest.function(
      'POST',
      `api/clients/gurulead/${clientId}`,
      null,
      null,
      query,
      {Authorization: `Bearer ${this.getToken()}`}
    )
  }

  async sendContinueCode(clientId){
    return new Promise((resolve) => {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}api/clients/${clientId}/phone-confirmation/request`
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            this.notificationLayer?.addNotification?.({
              message: error?.response?.data?.message
            })
          }
          resolve(error?.response)
        })
    })
  }

  // eslint-disable-next-line camelcase
  async checkContinueCode({client_id, code}){
    return new Promise((resolve) => {
      axios
        .patch(
          // eslint-disable-next-line camelcase
          `${process.env.VUE_APP_API_URL}api/clients/${client_id}/phone-confirmation/confirm`,
          {
            code
          }
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            this.notificationLayer?.addNotification?.({
              message: error?.response?.data?.message
            })
          }
          resolve(error?.response)
        })
    })
  }

  async checkToken(token){
    return new Promise((resolve) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}api/client/check_order/${token}`)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          if (error?.response?.status === 422) {
            this.notificationLayer?.addNotification?.({
              message: error?.response?.data?.message
            })
          }
          resolve(error?.response)
        })
    })
  }

  setDefaultTariff(data){
    if(data && data?.data?.tariffs){
      this.dashboardStore.$patch({
        defaultTariff: data.data.tariffs
      });
    }
  }

  async getDefaultTariffForCreditCalculator(){

    if(this.dashboardStore.$state.defaultTariff.length > 0){
      return this.dashboardStore.$state.defaultTariff;
    }

    const tariffRes = await apiRequest.function(
      'GET',
      `api/tariff/default`,
      [this.setDefaultTariff.bind(this)],
      null,
      null,
    );

    return tariffRes?.data?.tariffs || null;
  }

  setCalculatorData(sum, date, returnSum, returnDate){
    this.dashboardStore.$patch({
      calculatorData: {sum, date, returnSum, returnDate, sumFormated: numberWithSpaces(sum || 0)}
    });
    localStorage.setItem('calculatorData', JSON.stringify({sum, date, returnSum, returnDate}))
  }

  async updateOrder({ order, id, url = '' }) {
    return await apiRequest.function(
      'PATCH',
      `api/orders/${id}${url}`,
      null,
      null,
      order,
      { Authorization: `Bearer ${this.getToken()}` }
    )
  }

  async requestOrderStatus(orderId, authClient) {
    const { data, status } = await apiRequest.function(
      'get',
      `api/orders/${orderId}/getStatus${authClient ? '/auth' : ''}`,
      null,
      null,
      null,
      { Authorization: `Bearer ${this.getToken()}` }
    )

    return {data, status}
  }

  async getAddressSuggestions(localInput){
    if(!localInput || !localInput.length || localInput.length <= 3){
      return undefined;
    }
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + this.apiKey
      },
      body: JSON.stringify({ query: localInput })
    }

    let suggestions = undefined;

    await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', options)
      .then((response) => response.json())
      .then((result) => {
        suggestions = result.suggestions
      }).catch(() => {})

    return suggestions;
  }
}
