import {createI18n} from "vue-i18n";
import ru from "@/locales/ru.json";

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  legacy:false,
  pluralRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: (choice) => {
      if (choice === 0) {
        return 0;
      }
      const mod10 = choice % 10;
      const mod100 = choice % 100;

      if (mod10 === 1 && mod100 !== 11) {
        return 1; // 1 день
      }
      if (mod10 >= 2 && mod10 <= 4 && (mod100 < 10 || mod100 >= 20)) {
        return 2; // 2-4 дня
      }

      return 3; // 0, 5-20 дней
    }
  },
  messages:{
    ru
  },
});

export default i18n;
